import React from "react"
import Scrollspy from "react-scrollspy"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import Scroller from './scroller'
import Img from "gatsby-image"

export default class Header extends React.Component {
  render() {
    const { text, logo, description } = this.props;

    return (
      <Navbar className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav" expand="lg"
        collapseOnSelect={true}>
        <div className="container">

          <Nav.Link
            className="navbar-brand js-scroll-trigger text-white"
            href="#about"
            onClick={Scroller.handleAnchorScroll}>
            <Img  fluid={logo.childImageSharp.fluid} alt={description} style={{ width: 80, borderRadius: 40, pointerEvents: "none" }} />
          </Nav.Link >

          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="navbar-nav ml-auto my-2 my-lg-0">
              <Scrollspy className="navbar-nav"
                items={["about", "members", "events", "portfolio", "contact"]}
                currentClassName="active" rootEl={"#mainNav"} offset={-122}>
                <li className="nav-item">
                  <Nav.Link className={"js-scroll-trigger"} href="#about" onClick={Scroller.handleAnchorScroll}>{text.about}</Nav.Link>
                </li>
                <li className="nav-item">
                <Nav.Link className={"js-scroll-trigger"} href="#members" onClick={Scroller.handleAnchorScroll}>{text.members}</Nav.Link>
                </li>
                <li className="nav-item">
                  <Nav.Link className={"js-scroll-trigger"} href="#events" onClick={Scroller.handleAnchorScroll}>{text.events}</Nav.Link>
                </li>
                <li className="nav-item">
                  <Nav.Link className={"js-scroll-trigger"} href="#portfolio" onClick={Scroller.handleAnchorScroll}>{text.gallery}</Nav.Link>
                </li>
                <li className="nav-item">
                  <Nav.Link className={"js-scroll-trigger"} href="#contact" onClick={Scroller.handleAnchorScroll}>{text.contact}</Nav.Link>
                </li>
              </Scrollspy>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }
}
