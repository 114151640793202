/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"

import "./layout.scss"
import { StaticQuery, graphql } from "gatsby"

const getScrollNode = (element) => {
  return element.ownerDocument.scrollingElement || element.ownerDocument.documentElement
}

const isScrolled = (element) => {
  const scrollNode = getScrollNode(element)
  return scrollNode.scrollTop > 0
}

export default class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.siteContainer = React.createRef()

    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      scrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    const element = this.siteContainer.current
    this.setState({
      scrolled: isScrolled(element),
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    const element = this.siteContainer.current
    this.setState({
      scrolled: isScrolled(element),
    })
  }

  render() {
    let className = "site-container"

    if (this.props.className) className += ` ${this.props.className}`
    if (this.state.scrolled) className += " navbar-scrolled"

    return (
      <StaticQuery
        query={graphql`
          query {
            site: dataYaml {
              copyrights
              description
              logo {
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              navigation {
                text {
                  staff
                  home
                  about
                  events
                  gallery
                  contact
                  members
                  athletes
                  horses
                }
              }
            }
          }
    `}
        render={data => (
          <div
            className={className}
            ref={this.siteContainer}
            id="page-top">
            <Header description={data.site.description} text={data.site.navigation.text} logo={data.site.logo} />
            <main>{this.props.children}</main>
            <Footer copyrights={data.site.copyrights} />
          </div>
        )} />
    )
  }
}
